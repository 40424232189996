import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'helpers/api'

// ** fetch User Notifications
export const fetchUserNotifications = createAsyncThunk('notifications/fetchUserNotifications', async (params) => {
  const response = await api.get(`api/notification/user/list?numberPrefix=${params?.numberPrefix}&number=${params?.number}`)
  return response?.data
})

// ** read Notification
export const readUserNotification = createAsyncThunk('notifications/readUserNotification', async (params, { dispatch }) => {
  const values = params;
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const response = await api.post(`api/notification/readed`, values, config)
  dispatch(fetchUserNotifications({numberPrefix:values?.numberPrefix,number: values?.number}))
  return response?.data
})

// ** delete Notification
export const deleteUserNotification = createAsyncThunk('notifications/deleteUserNotification', async (params) => {
  const response = await api.delete(`api/notification/deleteFromUser/${params}`)
  return response.data;
})

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    Data: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchUserNotifications.fulfilled, (state, action) => {
      state.Data = action?.payload?.Data
    })
  }
})

export default notificationsSlice.reducer
