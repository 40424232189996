import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import NavigationTitle from 'components/common/navigationTitle';
import CustomButton from '../buttons/customButton';
import { ReactComponent as Voucher } from 'assets/images/icons/voucher.svg';
import { colors } from 'config/colors';
import { useTranslation } from 'react-i18next';
import { useFormik } from "formik"
import * as Yup from "yup";
import CustomizedInput from '../forms/customizedInput';
import { checkVoucher, saveRoomNumber } from 'store/apps/user';
import ReCAPTCHA from 'react-google-recaptcha';
import { updateLoading, updateToastVoucherModal } from 'store/apps/appConfig';
import { useLocation } from 'react-router-dom';

const VoucherModal = ({ closeModal, guestTime }) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [voucherError, setVoucherError] = useState(false);
  const [voucherErrorMessage, setVoucherErrorMessage] = useState(false);
  const [roomRequest, setRoomRequest] = useState(false);
  const recaptchaRef = React.createRef();
  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      code: ''
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .required(t('app.required'))
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(updateLoading(true));
      recaptchaRef.current.reset()
      recaptchaRef.current.executeAsync().then((token) => {
        let my_captcha_response = window.grecaptcha.getResponse()
        if (my_captcha_response) {
          dispatch(updateLoading(true));
          dispatch(checkVoucher(values?.code))
            .then((res) => {
              dispatch(updateLoading(false));
              if (res.payload?.Error) {
                setVoucherError(true)
                setVoucherErrorMessage(t(`errorsAPI.${res.payload?.Error}`))
                resetForm()
                setTimeout(() => {
                  setVoucherError(false)
                  setVoucherErrorMessage(false);
                }, 2000)
              }
              else {
                if (res?.payload?.Data?.voucher?.type === "guest") {
                  setRoomRequest(true)
                }
                else {
                  if (guestTime) {
                    setVoucherError(true)
                    setVoucherErrorMessage(t(`app.onlyGuestVouchers`))
                    resetForm()
                  }
                  else {
                    closeModal()
                    dispatch(saveRoomNumber(false))
                    if (location?.pathname === "/prices") {
                      setTimeout(() => {
                        dispatch(updateToastVoucherModal(true))
                      }, 800)
                    }
                  }
                }
              }
            })
        }
        else {
          setVoucherError(true)
          setVoucherErrorMessage(t(`errorsAPI.0`))
          resetForm()
        }
      })
    }
  });

  const formikRoom = useFormik({
    initialValues: {
      room: ''
    },
    validationSchema: Yup.object({
      room: Yup.string()
        .required(t('app.required'))
    }),
    onSubmit: (values) => {
      saveRoom(values)
    }
  })

  const saveRoom = (value) => {
    dispatch(saveRoomNumber(value))
    closeModal()
    if (location?.pathname === "/prices") {
      setTimeout(() => {
        dispatch(updateToastVoucherModal(true))
      }, 800)
    }
  }

  return (
    <Row className='h-100'>
      <Form onSubmit={roomRequest ? formikRoom.handleSubmit : formik.handleSubmit}>
        <Col xs="12" style={{ height: "17.1%" }}>
          <NavigationTitle title={t('app.applyVoucher')} path="/home" modal guestTime={guestTime} closeCustomModal={closeModal} icon={<Voucher className='m-0 p-0' style={{ fill: colors?.font, height: "18px", width: "18px" }} />} />
        </Col>
        <Col xs="12" style={{ height: "82.9%" }}>
          <Row className='mt-5'>
            <span className='m-0 p-0 text-3 weight-300'>{t(`app.${roomRequest ? "insertRoomNumber" : "insertVoucherCode"}`)}</span>
          </Row>
          <Row className='mt-3'>
            <CustomizedInput
              id={roomRequest ? "room" : "code"}
              name={roomRequest ? "room" : "code"}
              type="text"
              inputmode={roomRequest ? "numeric" : "text"}
              error={roomRequest ? formik.errors.room ? true : false : voucherError || formik.errors.code ? true : false}
              placeholder={t(`app.${roomRequest ? "roomNumber" : "code"}`)}
              onChange={roomRequest ? formikRoom.handleChange : formik.handleChange}
              value={roomRequest ? formikRoom.values.room : formik.values.code}
              formik={roomRequest ? formikRoom : formik}
            />
          </Row>
          <Row style={{ height: "35px" }} className="d-flex align-items-center">
            <span className='m-0 p-0 text-danger text-5 weight-300'>{roomRequest ? formik.errors.room : voucherErrorMessage ? voucherErrorMessage : formik.errors.code}</span>
          </Row>
          <Row>
            <Col xs="12" className='mt-4'>
              <CustomButton
                color="primary"
                label={t(`app.${roomRequest ? "applyVoucher" : "confirmVoucherCode"}`)}
                textSize="text-3 weight-300"
                onClick={() => roomRequest ? formikRoom.handleSubmit() : formik.handleSubmit()}
              />
            </Col>
          </Row>
        </Col>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_CAPTCHA_KEY}
          ref={recaptchaRef}
          size="invisible"
          style={{ display: "none" }}
        />
      </Form>
    </Row>
  );
}

export default VoucherModal;
