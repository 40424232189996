import React from 'react';
import { Row, Col } from 'reactstrap'
import CustomCard from './cards/customCard';
import { ReactComponent as ArrowBack } from 'assets/images/icons/arrowBack.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import { updateModalLanguage, updateFaqsLanguage } from 'store/apps/appConfig';
import { useDispatch } from 'react-redux';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { colors } from 'config/colors';

const NavigationTitle = ({ title, path, modal, icon, closeCustomModal, pathState, onClick, guestTime }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { handleTransition } = useRouterTransition(navigate);

  const closeModals = () => {
    dispatch(updateFaqsLanguage(false));
    dispatch(updateModalLanguage(false));
    if (closeCustomModal) {
      if (guestTime) {
        if (location?.pathname === "/home") {
          closeCustomModal();
        }
        if (pathState) {
          navTo(path, pathState)
        }
        else {
          navTo(path)
        }
      }
      else {
        closeCustomModal();
      }
    }
    else {
      if (!modal) {
        if (pathState) {
          navTo(path, pathState)
        }
        else {
          navTo(path)
        }
      }
    }
  }

  const navTo = (url, state) => {
    if (state) {
      handleTransition(url, state)
    }
    else {
      handleTransition(url)
    }
  };

  return (
    <Row className='h-100 d-flex align-items-end'>
      <Col xs="12" className='h-fit-content'>
        <Row>
          <Col xs="9" className='d-flex align-items-center'>
            <Row>
              <div className='m-0 p-0 d-flex align-items-center'>
                {
                  icon && <div className='m-0 p-0 me-1 d-flex'>{icon}</div>
                }
                <span className='m-0 p-0 text-1 weight-300'>{title}</span>
              </div>
            </Row>
          </Col>
          <Col xs="3" className='d-flex justify-content-end'>
            <Row onClick={onClick ? onClick : modal ? () => closeModals() : () => pathState ? navTo(path, pathState) : navTo(path)} style={{ height: "45px", width: "45px" }}>
              <Col xs="12" className="h-100">
                <CustomCard color="secondary">
                  <ArrowBack className='m-0 p-0' style={{ fill: colors.font, width: "15px", height: "15px" }} />
                </CustomCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default NavigationTitle;
