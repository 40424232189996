import React from "react"
import Router from "router";

function App() {

  return (
    <Router />
  );
}

export default App;


