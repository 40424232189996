import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'helpers/api'

// ** Payment Credit Card
export const checkPaymentCreditCard = createAsyncThunk('payment/checkPaymentCreditCard', async (params) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const response = await api.post(`api/bensaude/user/locker/authorize`, params, config)
  return response?.data
})

// ** Payment MB Way
export const checkPaymentMBWay = createAsyncThunk('payment/checkPaymentMBWay', async (params) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const response = await api.post(`api/bensaude/user/locker/MBWay`, params, config)
  return response?.data
})

// ** Consult MB Way
export const consultPaymentMBWay = createAsyncThunk('payment/consultPaymentMBWay', async (params) => {
  const response = await api.get(`payment/verify?identifier=${params}`)
  return response?.data
})

// * Consult Payment Methods

export const consultPaymentMethods = createAsyncThunk('payment/consultPaymentMethods', async (params) => {
  const response = await api.get(`api/marketplace/settings/paymentMethods/consult`)
  return response?.data
})

export const updateCloseIframe = createAsyncThunk('payment/updateCloseIframe', async (params) => {
  return params;
})

export const updateExistTransaction = createAsyncThunk('payment/updateExistTransaction', async (params) => {
  return params;
})

export const updatePaymentError = createAsyncThunk('payment/updatePaymentError', async (params) => {
  return params;
})

export const updateIframeReady = createAsyncThunk('payment/updateIframeReady', async (params) => {
  return params;
})

const initialState = {
  closeIframe: false,
  existTransaction: null,
  paymentError: false,
  iframeReady: false
}

export const paymentSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: { resetPaymentState: () => initialState },
  extraReducers: builder => {
    builder.addCase(updateCloseIframe.fulfilled, (state, action) => {
      state.closeIframe = action?.payload
    })
    builder.addCase(updateExistTransaction.fulfilled, (state, action) => {
      state.existTransaction = action?.payload
    })
    builder.addCase(updateIframeReady.fulfilled, (state, action) => {
      state.iframeReady = action?.payload
    })
    builder.addCase(updatePaymentError.fulfilled, (state, action) => {
      state.paymentError = action?.payload
    })
  }
})

export const { resetPaymentState } = paymentSlice.actions;
export default paymentSlice.reducer
