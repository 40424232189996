import { useState } from 'react';

export const useRouterTransition = (navigate) => {

  const [transitionType, setTransitionType] = useState('in');

  const handleTransition = (path,state) => {
    setTransitionType('out');

    setTimeout(() => {
      if(state){
        navigate(path, state);
      }
      else{
        navigate(path);
      }
    }, 300);
  }

  return { transitionType, handleTransition };
};
