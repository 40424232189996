import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { colors } from 'config/colors';
import { translationFlags } from 'config/translationFlags';
import { useDispatch, useSelector } from 'react-redux';
// Mudança icon
// import { ReactComponent as TerraNostraLogo } from 'assets/images/logos/TerraNostraLogo.svg';
import { ReactComponent as TerraNostraLogo } from 'assets/images/logos/TerraNostraPrincipalNewNoText.svg';
import { ReactComponent as NotificationsIcon } from 'assets/images/icons/notificationsBell.svg';
import { ReactComponent as Help } from 'assets/images/icons/IconHelp.svg';
import { updateFaqsLanguage, updateModalLanguage, updateLanguage, updateNotificationsModal, updateNotificationsContainerModal } from 'store/apps/appConfig';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomCard from 'components/common/cards/customCard';
import ContainerModal from 'components/common/modals/containerModal';
import NotificationsModal from 'components/common/modals/notificationsModal';
import { fetchSettings } from 'store/apps/marketplace';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { checkVisitingTime } from 'hooks/pricesAndSchedules';
import { pathnameCheckScheduleAndOffline, pathnamePaymentMissing } from 'config/appConfig';

const Navbar = () => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  let { handleTransition } = useRouterTransition(navigate);
  const location = useLocation();
  const [selectedCountry, setSelectedCountry] = useState(false);
  const notifications = useSelector(state => state?.notifications?.Data);
  const { voucher } = useSelector(state => state?.user);
  const { notificationsContainerModal, language } = useSelector(state => state?.appConfig);
  const { settings } = useSelector(state => state?.marketplace);

  const navTo = (url, state) => {
    if (state) {
      handleTransition(url, state)
    }
    else {
      handleTransition(url)
    }
  };

  const openLanguageChange = () => {
    dispatch(updateModalLanguage(true))
    dispatch(updateFaqsLanguage(false));
  }

  const openFaqs = () => {
    dispatch(updateModalLanguage(false))
    dispatch(updateFaqsLanguage(true));
  }

  const goHome = () => {
    dispatch(updateFaqsLanguage(false));
    dispatch(updateModalLanguage(false));
    navigate('/home');
  }

  const getSettings = () => {
    dispatch(fetchSettings({ type: "visitor" }))
  }

  const getNotificationsNotReaded = () => {
    const filteredNotifications = notifications.filter(item => item.readed === false);
    return (filteredNotifications.length);
  }

  useEffect(() => {
    setSelectedCountry(translationFlags.find(key => key?.code === language))
    dispatch(updateLanguage(language))
    i18n.changeLanguage(language);
  }, [language])

  useEffect(() => {
    dispatch(updateNotificationsModal(notificationsContainerModal))
  }, [notificationsContainerModal])

  useEffect(() => {
    if (settings) {
      if (pathnameCheckScheduleAndOffline.includes(location?.pathname)) {
        if (!settings?.system?.online) {
          if (location?.pathname !== '/home') {
            navTo('/system-down')
          }
        }
        else {
          if (location?.pathname !== "/home") {
            if (settings?.payments.some(payment => payment?.enabled)) {
              checkVisitingTime(settings?.schedules, dispatch, voucher !== '' ? voucher : false, navTo);
            }
            else {
              if (settings?.freeMode?.free) {
                checkVisitingTime(settings?.schedules, dispatch, voucher !== '' ? voucher : false, navTo);
              }
            }
          }
        }
      }
    }
    else {
      dispatch(fetchSettings({ type: "visitor" }))
        .then((res) => {
          if (res?.payload?.ResultCode === 1) {
            if (pathnameCheckScheduleAndOffline.includes(location?.pathname)) {
              if (!settings?.system?.online) {
                if (location?.pathname !== '/home') {
                  navTo('/system-down')
                }
              }
              else {
                if (location?.pathname !== "/home") {
                  if (settings?.payments.some(payment => payment?.enabled)) {
                    checkVisitingTime(res.payload?.Data?.schedules, dispatch, voucher !== '' ? voucher : false, navTo);
                  }
                  else {
                    if (settings?.freeMode?.free) {
                      checkVisitingTime(res.payload?.Data?.schedules, dispatch, voucher !== '' ? voucher : false, navTo);
                    }
                  }
                }
              }
            }
          }
        })
    }
  }, [location?.pathname, voucher, settings])

  useEffect(() => {
    dispatch(updateFaqsLanguage(false))
    dispatch(updateModalLanguage(false))
    dispatch(updateNotificationsModal(false))
  }, [location?.pathname])

  useEffect(() => {
    getSettings()
  }, [dispatch])

  useEffect(() => {
    if (settings) {
      if (!settings?.payments.some(payment => payment?.enabled) && pathnamePaymentMissing.includes(location?.pathname) && !settings?.freeMode?.free) {
        navTo('/service-down');
      }
    }
  }, [settings, pathnamePaymentMissing, location.pathname]);

  return (
    <Row style={{ height: "45px" }}>
      <ContainerModal isOpen={notificationsContainerModal}>
        <NotificationsModal closeModal={() => dispatch(updateNotificationsContainerModal(false))} />
      </ContainerModal>
      <Col xs={location?.pathname === "/home" ? "9" : "5"} className='h-100'>
        {
          location?.pathname === "/home" ?
            <Row className="h-100 w-fit-content">
              <Col xs="12" onClick={() => openFaqs()}>
                <CustomCard color="secondary">
                  <span className='m-0 p-0 px-2 text-4 weight-300'>{t('app.helpAndFaqs')}</span>
                </CustomCard>
              </Col>
            </Row>
            :
            <Row>
              <TerraNostraLogo className='p-0 m-0' onClick={() => goHome()} style={{ width: "auto", height: "45px" }} />
            </Row>
        }
      </Col>
      <Col xs={location?.pathname === "/home" ? "3" : "7"} className='h-100 d-flex justify-content-end '>
        <Row>
          {location?.pathname !== "/home" &&
            <Col className='mx-2'>
              <Row style={{ width: "45px" }} className="h-100" onClick={() => openFaqs()}>
                <Col xs="12">
                  <CustomCard color="secondary">
                    <div className='m-0 p-0 h-100 w-100 d-flex justify-content-center align-items-center'>
                      <Help id="modals" style={{ height: "30%", fill: colors.font }} />
                    </div>
                  </CustomCard>
                </Col>
              </Row>
            </Col>}
          {location?.pathname === "/access-lockers" &&
            <Col className='me-2'>
              <Row style={{ width: "45px" }} className="h-100" onClick={() => dispatch(updateNotificationsContainerModal(true))}>
                <Col xs="12">
                  <CustomCard color="secondary">
                    <div className='m-0 p-0 h-100 w-100 d-flex justify-content-center align-items-center position-relative'>
                      {(notifications?.length > 0 && getNotificationsNotReaded() > 0) && <div className='m-0 p-0 position-absolute d-flex justify-content-center align-items-center' style={{ background: "#FFBC64", borderRadius: "50%", width: 15, height: 15, top: 7, right: 9 }}>
                        {getNotificationsNotReaded() < 100 && <span className='p-0' style={{ fontSize: 9.5, marginTop: 1 }}>{getNotificationsNotReaded()}</span>}
                      </div>}
                      <NotificationsIcon className='m-0 p-0 w-auto' style={{ height: "42.5%", stroke: colors.font, fill: colors.font }} />
                    </div>
                  </CustomCard>
                </Col>
              </Row>
            </Col>}
          <Col id="modals">
            <Row style={{ width: "45px" }} className="h-100" id="modals">
              <Col xs="12" id="modals">
                <CustomCard color="secondary" id="modals">
                  <Col xs="12" id="modals" className='h-100 position-relative d-flex align-items-center justify-content-center' onClick={() => openLanguageChange()}>
                    <div className='border-radius w-100 h-100 position-absolute' id="modals" />
                    <Row className='px-1 flag' id="modals">
                      <div id="modals" className='m-0 p-0 h-100' style={{ width: "32.5px" }}>
                        {selectedCountry?.flag}
                      </div>
                    </Row>
                  </Col>
                </CustomCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Navbar;
