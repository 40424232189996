import React, { useEffect } from 'react';
import { ReactComponent as Lokk } from "components/common/loading/Lokk.svg";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { colors } from 'config/colors';

const Loading = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const styles = {
    container: {
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      display: 'flex',
      top: 0,
      left: 0,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(250, 250, 250, 0.95)',
      zIndex: "999"
    }
  };

  useEffect(() => {
    // Add the 'no-scroll' class to the body when the loading component is mounted
    document.body.classList.add('no-scroll');

    // Remove the 'no-scroll' class from the body when the loading component is unmounted
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <div style={styles.container}>
      <div id="loading-bar-spinner" className="spinner" style={{ marginTop: location?.pathname === "/checkout" ? "-50px" : 0 }}>
        <div className="spinner-icon"></div>
      </div>
      <Lokk className="position-absolute" style={{ width: "55px", marginTop: location?.pathname === "/checkout" ? "-55px" : "-5px", fill: colors.terra }} />
      {
        location?.pathname === "/checkout" &&
        <span className='p-0 position-absolute text-3 weight-400 mx-4 text-center' style={{ marginTop: "125px" }}>{t('app.waitProccessTkeTime')}</span>
      }
    </div>
  );
};

export default Loading;
