import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useDispatch, useSelector } from 'react-redux';
import { updateLoading, updateSocketStatus } from 'store/apps/appConfig';
import { capacityCheck } from 'store/apps/general';
import { fetchPrices, fetchSettings } from 'store/apps/marketplace';
import { accessUserLocker, activeLocker, setLockerCheckout, updateFreeLocker } from 'store/apps/user';
import { findPrices } from 'hooks/pricesAndSchedules';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchUserNotifications } from 'store/apps/notifications';
import { checkoutInfo, updateCheckoutSummaryTime, updateDisableCheckoutButton, updateErrorCheckoutLocker } from 'store/apps/checkout';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { updateCloseIframe, updateExistTransaction, updateIframeReady, updatePaymentError } from 'store/apps/payment';
import { useEffect } from 'react';
import { pathnameSocketFail } from 'config/appConfig';


export const Socket = ({ showIconStatus }) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let { handleTransition } = useRouterTransition(navigate);
  const socket = useSelector(state => state?.appConfig?.socket?.active);
  const { voucher, number, prefix: numberPrefix, activeLockerDoor: lockerDoor, activeLockerId: lockerId, freeLocker } = useSelector(state => state?.user);
  const websocketUrl = process.env.REACT_APP_API_WS_URL + '/ws/location-status?access_token=' + process.env.REACT_APP_API_TOKEN;

  const {
    readyState,
  } = useWebSocket(websocketUrl, {
    onOpen: () => changeSocketStatus(true, '::Socket Opened'),
    onClose: () => changeSocketStatus(false, '::Socket Closed'),
    onError: () => changeSocketStatus(false, '::Socket Error'),
    shouldReconnect: () => true,
    reconnectAttempts: Infinity,
    reconnectInterval: () => 1,
    onMessage: (e) => handleTrigger(e)
  })

  const connectionStatus = {
    [ReadyState.CONNECTING]: '::Connecting Socket',
    [ReadyState.OPEN]: '::Socket Opened',
    [ReadyState.CLOSING]: '::Closing Socket',
    [ReadyState.CLOSED]: '::Socket Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const navTo = (url, state) => {
    if (state) {
      handleTransition(url, state)
    }
    else {
      handleTransition(url)
    }
  };

  const changeSocketStatus = (active, status) => {
    dispatch(updateSocketStatus(active))
    console.log(status)
  }

  const handleFetchPrices = () => {
    dispatch(fetchPrices(voucher ? voucher : { type: "visitor" }))
      .then((res) => {
        if (res?.payload?.ResultCode === 1) {
          dispatch(updateFreeLocker(findPrices(res?.payload?.Data)))
        }
        else {
          console.log(res)
        }
      })
  }

  const fetchLockerData = () => {
    dispatch(
      accessUserLocker({
        numberPrefix: numberPrefix,
        number: number,
        pin: location?.state?.pin,
        locationId: process.env.REACT_APP_LOCATION_ID,
      })
    ).then(() => {
      dispatch(updateLoading(false));
    });
  }

  const handleFetchNotifications = () => {
    dispatch(fetchUserNotifications({ number: number, numberPrefix: numberPrefix }))
  }

  const handleFetchLockerDataRefresh = (e) => {
    if (e?.user?.numberPrefix && e?.user?.phoneNumber) {
      if (numberPrefix.toString() === e?.user?.numberPrefix && number.toString() === e?.user?.phoneNumber) {
        fetchLockerData()
      }
    }
  }

  const handleUpdateCapacity = () => {
    dispatch(capacityCheck(process.env.REACT_APP_LOCATION_ID))
  }

  const handleCheckoutSummary = (e) => {
    if (e?.user?.numberPrefix && e?.user?.phoneNumber) {
      if (numberPrefix.toString() === e?.user?.numberPrefix && number.toString() === e?.user?.phoneNumber) {
        dispatch(updateLoading(true));
        dispatch(checkoutInfo(lockerId))
          .then(() => {
            dispatch(updateLoading(false));
            const date = new Date();
            const options = { timeZone: 'Atlantic/Azores', hour12: false };
            const timeString = date.toLocaleTimeString('default', options);
            const [hour, minute] = timeString.split(':');
            dispatch(updateCheckoutSummaryTime({ hours: hour, minutes: minute }))
          })
      }
    }
  }

  const goHome = (e) => {
    if (e) {
      if (location?.state?.identifier === e) {
        navTo('/home')
      }
    }
  }

  const paymentCallBackMbWay = (e) => {
    if (numberPrefix.toString() === e?.user?.numberPrefix && number.toString() === e?.user?.phoneNumber) {
      dispatch(activeLocker({ lockerId: e?.locker?.id, doorNumber: e?.locker?.doorNumber }))
      navTo('/assigned-locker', { state: { pin: location?.state?.pin } })
    }
  }

  const noLockerCallBack = (e) => {
    if (numberPrefix.toString() === e?.user?.numberPrefix && number.toString() === e?.user?.phoneNumber) {
      navTo('/payment/refound', { state: { pin: location?.state?.pin, number: e?.user?.phoneNumber, prefix: e?.user?.numberPrefix } })
    }
  }

  const paymentCallBackCredit = (e) => {
    if (numberPrefix.toString() === e?.user?.numberPrefix && number.toString() === e?.user?.phoneNumber) {
      dispatch(activeLocker({ lockerId: e?.locker?.id, doorNumber: e?.locker?.doorNumber }))
      navTo('/assigned-locker', { state: { pin: location?.state?.pin } })
      dispatch(updateCloseIframe(true));
      dispatch(updateIframeReady(false));
    }
  }

  const paymentFail = () => {
    dispatch(updateCloseIframe(true));
    dispatch(updatePaymentError(true));
    dispatch(updateIframeReady(false));
  }

  const checkTransactionState = (e) => {
    if (e === location?.state?.identifier) {
      dispatch(updateExistTransaction(false))
    }
  }

  const openLocker = (e) => {
    if (e?.id === parseInt(lockerId) && e?.doorNumber === parseInt(lockerDoor) && e?.locker_users[0]?.numberPrefix === numberPrefix.toString() && e?.locker_users[0]?.number === number.toString()) {
      dispatch(updateLoading(false));
      dispatch(setLockerCheckout(true))
      dispatch(updateErrorCheckoutLocker(false))
      dispatch(updateDisableCheckoutButton(false))
    }
  }

  const getSettings = () => {
    dispatch(fetchSettings({ type: "visitor" }))
  }

  const fetchByLocation = (data, message) => {
    if (message === "AuthorizeSuccessNoLocker") {
      noLockerCallBack(data);
    }
    if (location?.pathname === "/home") {
      if (message === "UpdateCapacity") {
        handleUpdateCapacity(data);
      }
    }
    else if (location?.pathname === "/prices") {
      if (message === "RefreshLockers") {
        handleFetchPrices(data);
      }
    }
    else if (location?.pathname === "/access-lockers") {
      if (message === "RefreshLockers") {
        handleFetchLockerDataRefresh(data);
      }
      if (message === "PushNotification") {
        handleFetchNotifications(data);
      }
      if (message === "UpdateTransactions") {
        fetchLockerData(data);
      }
    }
    else if (location?.pathname === "/checkout-summary") {
      if (message === "RefreshLockers") {
        handleCheckoutSummary(data);
      }
    }
    else if (location?.pathname === "/payment/mbway/confirm") {
      if (message === "AuthorizeSuccess") {
        paymentCallBackMbWay(data);
      }
      if (message === "ExpiredTransaction") {
        goHome(data)
      }
    }
    else if (location?.pathname === "/payment/credit-card") {
      if (message === "ExpiredTransaction") {
        checkTransactionState(data);
      }
      if (message === "AuthorizeSuccess") {
        paymentCallBackCredit(data);
      }
      if (message === "AuthorizeFail") {
        paymentFail(data);
      }
    }
    else if (location?.pathname === "/checkout") {
      if (message === "TransactionDoorOpened") {
        openLocker(data)
      }
    }
    else {
      return null
    }
  };

  // useEffect(() => {
  //   if (!socket) {
  //     if (pathnameSocketFail.includes(location?.pathname) && !freeLocker) {
  //       navTo('/service-down');
  //     }
  //   }
  // }, [socket, freeLocker, location?.pathname])

  const handleTrigger = (e) => {
    if (e.data && e.data.charAt(0) === '{') {
      const parsedMsg = JSON.parse(e.data);
      console.log(parsedMsg?.MsgType)
      switch (parsedMsg?.MsgType) {
        case 'RefreshLockers':
          fetchByLocation(parsedMsg?.Data, "RefreshLockers");
          break;
        case 'AuthorizeSuccess':
          fetchByLocation(parsedMsg?.Data, "AuthorizeSuccess");
          break;
        case 'AuthorizeFail':
          fetchByLocation(parsedMsg?.Data, "AuthorizeFail");
          break;
        case 'ExpiredTransaction':
          fetchByLocation(parsedMsg?.Data, "ExpiredTransaction");
          break;
        case 'UpdateCapacity':
          fetchByLocation(parsedMsg?.Data, "UpdateCapacity");
          break;
        case 'TransactionDoorOpened':
          fetchByLocation(parsedMsg?.Data, "TransactionDoorOpened");
          break;
        case 'UpdateTransactions':
          fetchByLocation(parsedMsg?.Data, "UpdateTransactions");
          break;
        case 'AuthorizeSuccessNoLocker':
          fetchByLocation(parsedMsg?.Data, "AuthorizeSuccessNoLocker");
          break;
        case 'PushNotification':
          fetchByLocation(parsedMsg?.Data, "PushNotification")
          break;
        case 'UpdateSettings':
          getSettings()
          break;
        default:
          console.log(`Message type: ${parsedMsg?.MsgType}`);
          console.log(connectionStatus)
          break;
      }
    }
  }

  return (
    showIconStatus &&
    <div
      style={{ height: 10, width: 10, borderRadius: '100%', top: 8, left: 8, background: "red", position: "absolute" }}
      className={`m-0 p-0 pulse-infinite position-absolute bg-${socket ? "success" : "danger"}`}
    />
  )

};

export default Socket;
