import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'helpers/api'

// ** consult locker info
export const checkoutInfo = createAsyncThunk('checkout/checkoutInfo', async (params) => {
  const response = await api.get(`api/checkout/consult?lockerId=${params}`)
  return response?.data
})

// ** Checkout Locker
export const checkout = createAsyncThunk('checkout/checkout', async (params) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const response = await api.post(`api/checkout/proceed`, params, config)
  return response?.data
})

export const updateCheckoutSummaryTime = createAsyncThunk('checkout/updateCheckoutSummaryTime', async (params) => {
  return params;
})

export const updateErrorCheckoutLocker = createAsyncThunk('checkout/updateErrorCheckoutLocker', async (params) => {
  return params;
})

export const updateDisableCheckoutButton = createAsyncThunk('checkout/updateDisableCheckoutButton', async (params) => {
  return params;
})

const initialState = {
  summary: {
    data: false,
    hours: false,
    minutes: false
  },
  errorCheckoutLocker: false,
  disableCheckoutButton: false
}

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: { resetCheckoutState: () => initialState },
  extraReducers: builder => {
    builder.addCase(checkoutInfo.fulfilled, (state, action) => {
      state.summary.data = action?.payload?.Data
    })
    builder.addCase(updateErrorCheckoutLocker.fulfilled, (state, action) => {
      state.errorCheckoutLocker = action?.payload
    })
    builder.addCase(updateDisableCheckoutButton.fulfilled, (state, action) => {
      state.disableCheckoutButton = action?.payload
    })
    builder.addCase(updateCheckoutSummaryTime.fulfilled, (state, action) => {
      state.summary = {
        ...state.summary,
        hours: action?.payload?.hours,
        minutes: action?.payload?.minutes
      }
    })
  }
})

export const { resetCheckoutState } = checkoutSlice.actions;
export default checkoutSlice.reducer
