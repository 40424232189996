import React from 'react';
import { Input } from 'reactstrap';
import { ReactComponent as Eye } from 'assets/images/icons/eye.svg';
import { colors } from 'config/colors';

const CustomizedInput = ({ type, placeholder, value, disabled, onChange, id, name, className, error, activeInput, onFocus, focus, privacy,eyeFunction,maxLength ,inputmode}) => {

  return (
    <>
      <Input
        id={id}
        name={name}
        type={type}
        inputMode={inputmode}
        className={`${className} ${error ? "customInputError position-relative d-flex align-items-center" : "customInput position-relative d-flex align-items-center"}`}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        maxLength={maxLength}
        style={disabled ? { height: "40px", transition: "0.3s all", opacity: 0.3 } : { height: "40px", transition: "0.3s all", border: focus ? `1.5px solid ${activeInput ? colors?.font : colors?.disable}` : `1.5px solid ${colors?.font}`, color: focus ? activeInput ? colors?.font : colors?.disable : colors.font}}
        disabled={disabled}
        autoComplete="off"
        onFocus={onFocus}
      />
      {
        privacy &&
        <Eye onClick={eyeFunction} className='position-absolute p-0'
          style={focus ? { fill:colors.font,width: 18, height: "auto", right: 12, marginTop: 11, opacity: activeInput ? 1 : 0.3 } : { fill:colors.font,width: 15, height: "auto", right: 12, marginTop: 11, opacity: disabled ? 0.3 : 1 }}
        />
      }
    </>
  );
}

export default CustomizedInput;
