import { ReactComponent as FlagPortugal } from 'assets/images/icons/flags/FlagPortugal.svg';
import { ReactComponent as FlagSpain } from 'assets/images/icons/flags/FlagSpain.svg';
import { ReactComponent as FlagFrance } from 'assets/images/icons/flags/FlagFrance.svg';
import { ReactComponent as FlagUK } from 'assets/images/icons/flags/FlagUK.svg';

//First Element on array will be the country default value

export const translationFlags = [
  {
    language: "Português",
    code: "pt",
    flag: <FlagPortugal width="100%" height="100%"/>
  },
  {
    language: "Français",
    code: "fr",
    flag: <FlagFrance width="100%" height="100%"/>
  },
  {
    language: "Espanõl",
    code: "es",
    flag: <FlagSpain width="100%" height="100%" />
  },
  {
    language: "English",
    code: "en",
    flag: <FlagUK width="100%" height="100%" />
  },
]