import Portal from 'helpers/Portal';
import React from 'react';
import { Row, Col } from 'reactstrap';

const ContainerModal = ({ children,isOpen }) => {

  return (
    <Portal isOpen={isOpen} customModal>
      <Row className='m-0 w-100 h-100'>
        <Col xs="12" style={{ height: "45px" }}>
        </Col>
        <Col xs="12" style={{ height: "calc(100% - 45px)" }}>
          {children}
        </Col>
      </Row>
    </Portal>
  );
}

export default ContainerModal;
