import React from 'react';
import ReactDom from 'react-dom';
import { colors } from 'config/colors';

const Portal = ({ children, isOpen, customModal}) => {

  if (isOpen === false) return null;

  const styles = {
    container: {
      position: 'absolute',
      bottom: '0',
      left: '0',
      zIndex: customModal ? '65' : '50',
      height: customModal ? '100%' : 'calc(100% - 71px)',
      width: '100%',
      backgroundColor: customModal ? colors?.portalBackground : colors.white,
    }
  }

  return ReactDom.createPortal(
    <div style={styles.container}>
      <div className={`m-0 w-100 h-100 ${customModal ? "p-4" : "px-4 p-0 pb-4"}`}>
        {children}
      </div>
    </div>,
    document.getElementById('content-container') ? document.getElementById('content-container') : document.body
  )
}

export default Portal