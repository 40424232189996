import React, { useState, useEffect } from 'react';
import { Row, Col, Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from 'config/colors';
import NavigationTitle from 'components/common/navigationTitle';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronDown } from 'assets/images/icons/chevronDown.svg';
import { ReactComponent as Trash } from 'assets/images/icons/trash.svg';
import { updateLoading } from 'store/apps/appConfig';
import { readUserNotification, deleteUserNotification, fetchUserNotifications } from 'store/apps/notifications';
import { notifications } from 'config/notifications';
import { ReactComponent as Power } from 'assets/images/logos/PoweredByLokk.svg';


const NotificationsModal = ({ closeModal }) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState('');
  const [deleteErrorId, setDeleteErrorId] = useState(false);
  const numberPrefix = useSelector(state => state.user.prefix);
  const number = useSelector(state => state.user.number);
  const notificationsInfo = useSelector(state => state?.notifications?.Data);
  const {language} = useSelector(state => state?.appConfig);

  const toggle = (id) => {
    setOpen(prevOpen => (prevOpen === id ? '' : id));
  };


  const readNotification = (id, rowId) => {
    dispatch(readUserNotification({
      number: number,
      numberPrefix: numberPrefix,
      notificationId: id,
      readed: 1,
      rowId: rowId
    }))
  }

  const deleteNotification = (e, rowId) => {
    e.stopPropagation();
    dispatch(updateLoading(true));
    if(!deleteErrorId){
      dispatch(deleteUserNotification(rowId))
        .then((res) => {
          if (res?.payload?.ResultCode === 1) {
            dispatch(fetchUserNotifications({ number: number, numberPrefix: numberPrefix }))
              .then(() => {
                dispatch(updateLoading(false));
              })
          }
          else {
            dispatch(updateLoading(false));
            setDeleteErrorId(rowId);
          }
        })
    }
  }

  useEffect(() => {
    if (deleteErrorId) {
      setTimeout(() => {
        setDeleteErrorId(false);
      }, [2000])
    }
  }, [deleteErrorId])

  return (
    <Row className='h-100'>
      <Power style={{ width: "90px", height: "20px", bottom: 10, left: "calc(50% - 45px)",fill:colors.font }} className="m-0 m p-0 position-absolute" />
      <Col xs="12" style={{ height: "17.1%" }}>
        <NavigationTitle title={t(`app.notifications`)} modal closeCustomModal={closeModal} />
      </Col>
      <Col xs="12" style={{ height: "calc(82.9% - 50px)" }} >
        <Row className='h-100 pb-5'>
          {
            notificationsInfo ?
              <Accordion flush open={open} toggle={toggle} className="overflow-auto customScroll h-100 mt-5 px-2 weight-300">
                {
                  notificationsInfo && notificationsInfo.map((item, index) => (
                    <div
                      key={index}
                      className={`${index === 0 ? "mb-3" : index === notificationsInfo?.length - 1 ? "mt-3" : "my-3"} ${deleteErrorId === item?.rowId ? "error" : ""}`}
                      style={{ borderRadius: 10, border: `1px solid ${deleteErrorId === item?.rowId ? colors.error : "transparent"}` }}
                    >
                      <AccordionItem
                        onClick={!item?.readed ? () => readNotification(item?.id, item?.rowId) : null}>
                        <AccordionHeader targetId={`${index + 1}`}>
                          <Col xs="10">
                            <Row className='d-flex align-items-center ps-2'>
                              <span className='m-0 p-0 w-fit-content' style={{ color: notifications[item?.type] }}>{item?.readed ? "○" : "●"}</span>
                              <span className='m-0 w-fit-content p-0 ps-1 text-5' style={{ overflowWrap: "break-word" }}>
                                {item?.subject[language]}
                              </span>
                            </Row>
                          </Col>
                          <Col xs="2">
                            <Row className='d-flex justify-content-end align-items-center pe-2'>
                              <Trash style={{ width: "13px", height: "13px" }} className="m-0 p-0 me-3" onClick={(e) => deleteNotification(e, item?.rowId)} />
                              <ChevronDown style={{ fill:colors.font,width: "12px", height: "12px", transform: `rotate(${open === `${index + 1}` ? "180deg" : "0"})`, transition: "0.3s all" }} className="m-0 p-0" />
                            </Row>
                          </Col>
                        </AccordionHeader>
                        <AccordionBody accordionId={`${index + 1}`}>
                          <Row className='px-2'>
                            <span className='m-0 p-0 text-5' style={{ wordBreak: "break-word" }}>
                              {item?.text[language]}
                            </span>
                          </Row>
                        </AccordionBody>
                      </AccordionItem>
                    </div>
                  ))
                }
              </Accordion>
              :
              <Col xs="12" className='pt-5'>
                <Row className='mt-3'>
                  <span className='m-0 p-0 text-center'>{t('app.noNotifications')}</span>
                </Row>
              </Col>
          }
        </Row>
      </Col>
    </Row>
  );
}

export default NotificationsModal;
