import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { colors } from 'config/colors';
import Navbar from 'components/navbar';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'components/common/loading/Loading';
import Portal from 'helpers/Portal';
import FaqsModal from 'components/common/modals/faqsModal';
import LanguageModal from 'components/common/modals/languageModal';
import { updateHeight, updateVoucherContainerModal } from 'store/apps/appConfig';
import { ReactComponent as Power } from 'assets/images/logos/PoweredByLokk.svg';
import Socket from 'helpers/socket';
import ContainerModal from 'components/common/modals/containerModal';
import VoucherModal from 'components/common/modals/voucherModal';
import { updateGuestTime } from 'store/apps/marketplace';
import { useLocation } from 'react-router-dom';

const AppLayout = ({ children }) => {

  const [safeAreaHeight, setSafeAreaHeight] = useState(window.innerHeight);
  const dispatch = useDispatch();
  const location = useLocation();
  const { voucherContainerModal, screenAvailableHeight, loading, faqsModalOpen, languageModalOpen } = useSelector(state => state.appConfig);
  const { guestTime } = useSelector(state => state.marketplace);

  const closeVoucherModal = () => {
    dispatch(updateVoucherContainerModal(false))
    dispatch(updateGuestTime(false))
  }

  useEffect(() => {
    function handleResize() {
      const newSafeAreaTop = window.top !== window ? 0 : window.screenTop;
      const newSafeAreaHeight = window.innerHeight - newSafeAreaTop;
      setSafeAreaHeight(newSafeAreaHeight);
      dispatch(updateHeight(newSafeAreaHeight));
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!screenAvailableHeight) {
      dispatch(updateHeight(window.innerHeight - (window.top !== window ? 0 : window.screenTop)));
    }
  }, [screenAvailableHeight])

  return (
    <>
      <Row className='m-0 p-0 position-relative overflow-hidden' style={{ background: `${colors?.white}`, minHeight: `${safeAreaHeight}px` }} >
        <Socket token={process.env.REACT_APP_API_TOKEN} showIconStatus />
        {
          loading &&
          <Loading />
        }
        <Power style={{ width: "90px", height: "20px", bottom: 10, left: "calc(50% - 45px)", fill: colors.font }} className="m-0 m p-0 position-absolute" />
        <span className='position-absolute text-6 w-auto weight-300' style={{bottom: 5, right:0 }}>
          {process.env.REACT_APP_VERSION}
        </span>
        <ContainerModal isOpen={location.pathname === "/home" ? false : voucherContainerModal}>
          <VoucherModal closeModal={() => closeVoucherModal()} guestTime={guestTime} />
        </ContainerModal>
        <Col xs="12">
          <Row className='p-4 h-100'>
            <Col xs="12" id="navbar" style={{ height: "45px" }}>
              <Navbar />
            </Col>
            <Col xs="12" id="content-container" style={{ height: "calc(100% - 85px)" }} className="overflow-hidden">
              <Portal isOpen={languageModalOpen ? languageModalOpen : faqsModalOpen}>
                {
                  languageModalOpen
                    ?
                    <LanguageModal />
                    :
                    <FaqsModal />
                }
              </Portal>
              {children}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default AppLayout;
