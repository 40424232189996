import { updateVoucherContainerModal } from "store/apps/appConfig";
import { updateGuestTime } from "store/apps/marketplace";

export const findPrices = (prices) => {
  return prices.every((item) => {
    const priceToCheck = item?.finalPrice !== undefined && item?.finalPrice !== null
      ? (item?.finalPrice !== false ? item.finalPrice : item?.price)
      : item?.price;

    return !(priceToCheck > 0);
  });
};

export const checkVisitingTime = (schedules, dispatch, voucher, navTo) => {
  const azoresTime = new Date().toLocaleTimeString('pt-PT', { timeZone: 'Atlantic/Azores', hour12: false, hour: '2-digit', minute: '2-digit' });
  const currentTime = azoresTime.slice(0, 5);
  const visitorSchedule = schedules.find(schedule => {
    const startTime = schedule.start;
    const endTime = schedule.end;
    return currentTime >= startTime && currentTime <= endTime && schedule.type === 'visitor';
  });
  const guestSchedule = schedules.find(schedule => {
    const startTime = schedule.start;
    const endTime = schedule.end;
    return currentTime >= startTime && currentTime <= endTime && schedule.type === 'guest';
  });
  if (visitorSchedule && visitorSchedule) {
    if (voucher?.type !== 'guest') {
      dispatch(updateVoucherContainerModal(false));
      dispatch(updateGuestTime(false));
    }
  }
  if (guestSchedule && !visitorSchedule) {
    if (voucher?.type !== 'guest' || voucher === '') {
      dispatch(updateVoucherContainerModal(true))
      dispatch(updateGuestTime(true))
    }
  }
  if (!guestSchedule && !visitorSchedule) {
    navTo('/closed')
  }
};

