import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'helpers/api'

// ** capacity check
export const capacityCheck = createAsyncThunk('general/capacityCheck', async (params, { getState, dispatch }) => {
  const response = await api.get(`api/bensaude/capacity/check/${params}`)
  return response?.data
})

// ** cativation price check
export const cativationPriceCheck = createAsyncThunk('general/cativationPriceCheck', async (params, { getState, dispatch }) => {
  const response = await api.get(`api/marketplace/cativationPrice/list?type=${params}`)
  return response?.data
})

export const termsAndConditions = createAsyncThunk('general/termsAndConditions', async (params) => {
  const response = await api.get(`api/terms/list`)
  return response?.data
})

const initialState = {
  capacity: null
}

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: { resetGeneralState: () => initialState },
  extraReducers: builder => {
    builder.addCase(capacityCheck.fulfilled, (state, action) => {
      if(action?.payload?.ResultCode === 1){
        state.capacity = action.payload?.Data
      }
      else{
        state.capacity = action.payload?.Data
      }
    })
  }
})

export const { resetGeneralState } = generalSlice.actions;
export default generalSlice.reducer
