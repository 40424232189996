// ** Toolkit imports
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers} from 'redux'
import storage from "redux-persist/lib/storage"
import {persistStore, persistReducer} from "redux-persist"

// ** Reducers

import appConfig from '../apps/appConfig'
import user from '../apps/user'
import checkout from '../apps/checkout'
import general from '../apps/general'
import marketplace from '../apps/marketplace'
import payment from '../apps/payment'
import notifications from '../apps/notifications'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ["auth","user","marketplace","general","appConfig"]
}

const rootReducer = combineReducers({
  appConfig,
  user,
  checkout,
  general,
  marketplace,
  payment,
  notifications
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  })
})

const persistor = persistStore(store);

export { store, persistor }