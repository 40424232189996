import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'helpers/api';

export const updateLanguage = createAsyncThunk('appConfig/updateLanguage', async (params) => {
  return params;
})

export const updateModalLanguage = createAsyncThunk('appConfig/updateModalLanguage', async (params) => {
  return params;
})

export const updateFaqsLanguage = createAsyncThunk('appConfig/updateFaqsLanguage', async (params) => {
  return params;
})

export const updateLoading = createAsyncThunk('appConfig/updateLoading', async (params) => {
  return params;
})

export const updateHeight = createAsyncThunk('appConfig/updateHeight', async (params) => {
  return params;
})

export const updateNotificationsModal = createAsyncThunk('appConfig/updateNotificationsModal', async (params) => {
  return params;
})

export const updateLastNavigation = createAsyncThunk('appConfig/updateLastNavigation', async (params) => {
  return params;
})

export const updateSocketStatus = createAsyncThunk('appConfig/updateSocketStatus', async (params) => {
  return params;
})

export const updateVoucherContainerModal = createAsyncThunk('appConfig/updateVoucherContainerModal', async (params) => {
  return params;
})

export const updateNotificationsContainerModal = createAsyncThunk('appConfig/updateNotificationsContainerModal', async (params) => {
  return params;
})

export const updateMyLockersContainerModal = createAsyncThunk('appConfig/updateMyLockersContainerModal', async (params) => {
  return params;
})

export const updateBillingContainerModal = createAsyncThunk('appConfig/updateBillingContainerModal', async (params) => {
  return params;
})

export const updateToastVoucherModal = createAsyncThunk('appConfig/updateToastVoucherModal', async (params) => {
  return params;
})

export const fetchFaqs = createAsyncThunk('appConfig/fetchFaqs', async () => {
  const response = await api.get('api/faqs/list')
  return response?.data
})

export const appConfigSlice = createSlice({
  name: 'theme',
  initialState: {
    language: "pt",
    screenAvailableHeight: false,
    languageModalOpen: false,
    faqsModalOpen: false,
    notificationsModalOpen: false,
    loading: false,
    lastNavigation: false,
    voucherContainerModal: false,
    toastVoucherModal: false,
    notificationsContainerModal: false,
    myLockersContainerModal: false,
    billingContainerModal: false,
    socket: {
      active: false,
    },

  },

  reducers: {},
  extraReducers: builder => {
    builder.addCase(updateLanguage.fulfilled, (state, action) => {
      state.language = action.payload
    })
    builder.addCase(updateModalLanguage.fulfilled, (state, action) => {
      state.languageModalOpen = action.payload
    })
    builder.addCase(updateNotificationsModal.fulfilled, (state, action) => {
      state.notificationsModalOpen = action.payload
    })
    builder.addCase(updateFaqsLanguage.fulfilled, (state, action) => {
      state.faqsModalOpen = action.payload
    })
    builder.addCase(updateLoading.fulfilled, (state, action) => {
      state.loading = action.payload
    })
    builder.addCase(updateLastNavigation.fulfilled, (state, action) => {
      state.lastNavigation = action.payload
    })
    builder.addCase(updateHeight.fulfilled, (state, action) => {
      state.screenAvailableHeight = action.payload
    })
    builder.addCase(updateVoucherContainerModal.fulfilled, (state, action) => {
      state.voucherContainerModal = action.payload
    })
    builder.addCase(updateNotificationsContainerModal.fulfilled, (state, action) => {
      state.notificationsContainerModal = action.payload
    })
    builder.addCase(updateMyLockersContainerModal.fulfilled, (state, action) => {
      state.myLockersContainerModal = action.payload
    })
    builder.addCase(updateBillingContainerModal.fulfilled, (state, action) => {
      state.billingContainerModal = action.payload
    })
    builder.addCase(updateToastVoucherModal.fulfilled, (state, action) => {
      state.toastVoucherModal = action.payload
    })
    builder.addCase(updateSocketStatus.fulfilled, (state, action) => {
      state.socket = {
        active: action.payload
      }
    })
  }
})

export default appConfigSlice.reducer
